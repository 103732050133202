<template>
  <div>
    <Environment v-if="environment != 'production'" />
    <Header />
    <TrainingRequestForm />
    <Footer />
  </div>
</template>

<script>

// Components
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import TrainingRequestForm from '../components/TrainingRequestForm.vue'

export default {
  name: 'Training Request',
  components: {
    Header,
    Footer,
    TrainingRequestForm
  },
  setup() {
    const environment = process.env.VUE_APP_ENVIRONMENT

    return {
    environment
    }
  }
}
</script>

<style lang="scss">
  
</style>
